<template>
  <div class="solution-m">
    <div class="soul-1">
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assetsv2/h5/solution/ban-1.png" width="100%"/>
    </div>
    <div class="soul">
      <div class="title-g">
        <p class="title">云智能调度平台</p>
        <div class="line"></div>
        <p content="detail">通过大数据平台架构，荷载海量数据的基础上，达到了百万级的高并发响应能力，实现了快速的智能调度能力</p>
      </div>
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/solution/ban-4.png" width="100%"/>
    </div>
    <div class="soul">
      <div class="title-g">
        <p class="title">供应链创新</p>
        <div class="line"></div>
        <p content="detail">顺风大运和金融机构共同研发，采用区块链征信技术，提供基于实时业务的运费支付，创新式的推动了物流供应链的发展。并且得到了金融机构的大力支持</p>
      </div>
      <img src="http://admin-plat-1302681310.cos.ap-beijing.myqcloud.com/test/date/%E8%B5%84%E6%BA%90%202%403x.png" width="100%"/>
    </div>
    <div class="soul">
      <div class="title-g">
        <p class="title">司机管理平台</p>
        <div class="line"></div>
        <p content="detail">基于车辆基础数据、车辆业务数据、车辆行驶数据、车辆服务数据、客户评价数据，构建了司机的画像标签体系、价值分层体系、运营管理体系，规范司机行为，促进司机服务意识，提升运输服务保障</p>
      </div>
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/solution/ban-2.png" width="100%"/>
    </div>
<!--    <div class="soul">
      <div class="title-g">
        <p class="title">云安全监控平台</p>
        <div class="line"></div>
        <p content="detail">基于车联网技术、生物识别技术、电子围栏技术，形成了自动监控、自动分析、自动预警的全智能化，云安全监控体系</p>
      </div>
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/solution/ban-3.png" width="100%"/>
    </div>-->
    <div class="soul">
      <div class="title-g">
        <p class="title">一站式承运商合作伙伴</p>
        <div class="line"></div>
        <p content="detail">平台可以为承运商提供专业、高效、便捷的一站式服务体验，不仅实现了运力智能管理而且能够购买保险，实时跟踪，提供发票等全流程服务</p>
      </div>
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/solution/ban-5.png" width="100%"/>
    </div>
    <div class="soul">
      <div class="title-g">
        <p class="title">安全风控保障</p>
        <div class="line"></div>
        <p content="detail">
          基于车联网技术、生物识别技术、电子围栏技术，形成了自动监控、自动分析、自动预警的全智能化，云安全监控体系
          <br>
          平台可以购买货运保险，为货物、车辆及司机提供了保障。同时车辆均装有“北斗”导航系统和司机疲劳驾驶监控，可随时查看车辆及司机状况，道路运输更放心
        </p>
      </div>
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/solution/ban-6.png" width="100%"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "solution"
}
</script>

<style lang="less">
.solution-m {
  padding-bottom: 46px;

  .title-g {
    margin-top: 55px;
    text-align: left;
    margin-bottom: 28px;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #222222;
      line-height: 30px;
      letter-spacing: 1px;
    }

    .line {
      width: 18px;
      height: 1px;
      background: #0274F6;
      display: inline-block;
      margin-top: 5px;
      margin-bottom: 8px;
    }

    .detail {
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }

  .soul {
    padding: 0 18px;
  }
}
</style>
