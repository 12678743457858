<template>
  <div class="solution">
    <div class="top-banner">
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assetsv2/solution/sl-1.png" width="100%"/>
    </div>
    <div class="contain">
      <el-row class="sl-4">
        <el-col :span="15">
          <div class="tit">
            <p class="title">云智能调度平台</p>
            <div>
              <div class="lines"></div>
            </div>
            <p class="content">
              通过大数据平台架构，荷载海量数据的基础上，达到了百万级的高并发响应能力，实现了快速的智能调度能力
            </p>
          </div>
        </el-col>
        <el-col :span="9">
          <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/solution/sl-4.jpg" width="100%"/>
        </el-col>
      </el-row>
      <el-row class="sl-3">
        <el-col :span="9">
          <img src="http://admin-plat-1302681310.cos.ap-beijing.myqcloud.com/test/date/%E8%B5%84%E6%BA%90%202%403x.png" width="100%"/>
        </el-col>
        <el-col :span="15">
          <div class="tit right">
            <p class="title">供应链创新</p>
            <div>
              <div class="linesright"></div>
            </div>
            <p class="contentright">
              顺风大运和金融机构共同研发，采用区块链征信技术，提供基于实时业务的运费支付，创新式的推动了物流供应链的发展。并且得到了金融机构的大力支持
            </p>
          </div>
        </el-col>
      </el-row>
      <el-row class="sl-2">
        <el-col :span="15">
          <div class="tit">
            <p class="title">司机管理平台</p>
            <div>
              <div class="lines"></div>
            </div>
            <p class="content">
              基于车辆基础数据、车辆业务数据、车辆行驶数据、车辆服务数据、客户评价数据，构建了司机的画像标签体系、价值分层体系、运营管理体系，规范司机行为，促进司机服务意识，提升运输服务保障
            </p>
          </div>
        </el-col>
        <el-col :span="9">
          <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/solution/sl-2.jpg" width="100%"/>
        </el-col>
      </el-row>
      <el-row class="sl-5">
        <el-col :span="9">
          <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/solution/sl-6.jpg" width="100%"/>
        </el-col>
        <el-col :span="15">
          <div class="tit right">
            <p class="title">一站式承运商合作伙伴</p>
            <div>
              <div class="linesright"></div>
            </div>
            <p class="contentright">
              平台可以为承运商提供专业、高效、便捷的一站式服务体验，不仅实现了运力智能管理而且能够购买保险，实时跟踪，提供发票等全流程服务
            </p>
          </div>
        </el-col>
      </el-row>
      <el-row style='padding-bottom:1.50rem' class="sl-6">
        <el-col :span="15">
          <div class="tit">
            <p class="title">安全风控保障</p>
            <div>
              <div class="lines"></div>
            </div>
            <p class="content">
              基于车联网技术、生物识别技术、电子围栏技术，形成了自动监控、自动分析、自动预警的全智能化，云安全监控体系
              <br>
              平台可以购买货运保险，为货物、车辆及司机提供了保障。同时车辆均装有“北斗”导航系统和司机疲劳驾驶监控，可随时查看车辆及司机状况，道路运输更放心
            </p>
          </div>
        </el-col>
        <el-col :span="9">
          <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/solution/sl-5.jpg" width="100%"/>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "solution"
}
</script>

<style lang="less">
.solution {
  margin-top:0.90rem;
  .contain {
    .sl-2, .sl-3, .sl-4, .sl-5, .sl-6 {
      position:relative;
      float: left;
      padding: 1.00rem 0;
    }

    .tit {
      position: relative;
      float: left;
      text-align: left;
      padding-top: 10%;

      &.right {
        text-align: right;
      }

      .title {
        position: relative;
        float: left;
        width:100%;
        font-size: 0.30rem;
        font-weight: 600;
        color: #222222;
        line-height: 0.42rem;
      }

      .lines {
        position: relative;
        float: left;
        background: #0274F6;
        width: 0.34rem;
        height: 0.03rem;
        margin-top:0.10rem;
        margin-right:100%;
      }
      .linesright{
        position:relative;
        float: right;
        background: #0274F6;
        width: 0.34rem;
        height: 0.03rem;
        margin-top:0.10rem;
        margin-right:0%;
        margin-left:100%;
      }

      .content {
        position: relative;
        float: left;
        width: 65%;
        font-size: 0.16rem;
        font-weight: 400;
        color: #222;
        line-height: 0.3rem;
        margin-top: 0.18rem;
      }
      .contentright {
        position: relative;
        float: right;
        width: 65%;
        font-size: 0.16rem;
        font-weight: 400;
        color: #222;
        line-height: 0.3rem;
        margin-top: 0.18rem;
      }
    }
  }
}
</style>
